import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a02fed20 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _8365f1f0 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _e112f0e2 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _3704c632 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _83af3ba6 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _7168697a = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _6c1be512 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _4f98544c = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _57896062 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _764020aa = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _17988c05 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _22558760 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _7da67dcd = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _17ec0e96 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _97f6a55e = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _20209a2c = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _02c44e36 = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _e60275a8 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _12e2fd94 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _92de21f2 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _a02fed20,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _8365f1f0,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _e112f0e2,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _3704c632,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _83af3ba6,
    name: "receipt"
  }, {
    path: "/search",
    component: _7168697a,
    name: "search"
  }, {
    path: "/search-booking",
    component: _6c1be512,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _4f98544c,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _57896062,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _764020aa,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _17988c05,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _22558760,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _7da67dcd,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _17ec0e96,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _97f6a55e,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _20209a2c,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _02c44e36,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _e60275a8,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _12e2fd94,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _92de21f2,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
